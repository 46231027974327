const states = [
  { key: 'CA-AB', value: 'CA-AB', label: 'Alberta', enabled: 1 },
  { key: 'CA-BC', value: 'CA-BC', label: 'British Columbia', enabled: 1 },
  { key: 'CA-MB', value: 'CA-MB', label: 'Manitoba', enabled: 1 },
  { key: 'CA-NB', value: 'CA-NB', label: 'New Brunswick', enabled: 1 },
  {
    key: 'CA-NL',
    value: 'CA-NL',
    label: 'Newfoundland and Labrador',
    enabled: 1,
  },
  { key: 'CA-NS', value: 'CA-NS', label: 'Nova Scotia', enabled: 1 },
  { key: 'CA-ON', value: 'CA-ON', label: 'Ontario', enabled: 1 },
  { key: 'CA-PE', value: 'CA-PE', label: 'Prince Edward Island', enabled: 1 },
  { key: 'CA-QC', value: 'CA-QC', label: 'Quebec', enabled: 1 },
  { key: 'CA-SK', value: 'CA-SK', label: 'Saskatchewan', enabled: 1 },
  { key: 'CA-NT', value: 'CA-NT', label: 'Northwest Territories', enabled: 1 },
  { key: 'CA-NU', value: 'CA-NU', label: 'Nunavut', enabled: 1 },
  { key: 'CA-YT', value: 'CA-YT', label: 'Yukon', enabled: 1 },
];

export default states;
