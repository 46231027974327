import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import StepsNav from './StepsNav';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { parseISO, format } from 'date-fns';

const ConfirmStep = (props) => {
  const { handleSubmit } = useForm();
  const { actions, state } = useStateMachine({ updateAction });
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();

  const [selectedEventDetails, setSelectedEventDetails] = useState([]);

  useEffect(() => {
    const fetchSelectedEvent = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/events/${state.selectedEvent}`,
      );

      if (!response.ok) {
        throw new Error('Could not get selected event!');
      }

      const responseData = await response.json();
      setIsLoading(false);
      setSelectedEventDetails(responseData);
    };

    fetchSelectedEvent().catch((error) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
  }, [
    actions,
    state.dobDay,
    state.dobMonth,
    state.dobYear,
    state.selectedEvent,
  ]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (httpError) {
    return (
      <Box>
        <Alert severity="error">{httpError}</Alert>
      </Box>
    );
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const confirm = window.confirm(
      'You will now be redirected to our secure payment page',
    );

    if (!confirm) {
      return;
    }

    const booking = {
      email: state.email,
      phone: state.phone,
      firstName: state.firstName,
      lastName: state.lastName,
      address: state.address,
      city: state.city,
      region: state.region,
      country: state.country,
      postalCode: state.postalCode,
      dob: state.consolidatedDob,
      event: state.selectedEvent,
      testingComponents: state.cartItems,
      token: state.token,
    };

    const formData = {
      ordName: encodeURIComponent(booking.firstName + ' ' + booking.lastName),
      ordEmailAddress: encodeURIComponent(booking.email),
      ordPhoneNumber: encodeURIComponent(booking.phone),
      ordAddress1: encodeURIComponent(booking.address),
      ordCity: encodeURIComponent(booking.city),
      ordPostalCode: encodeURIComponent(booking.postalCode),
      approvedPage: encodeURIComponent(
        process.env.REACT_APP_PAYMENT_APPROVED_PAGE,
      ),
    };

    const response = await fetch(
      `${process.env.REACT_APP_PAYMENT_PAGE_URL}` +
        `?merchant_id=${process.env.REACT_APP_PAYMENT_MERCHANT_ID}` +
        `&hashValue=${process.env.REACT_APP_PAYMENT_HASH_VALUE}` +
        `&trnAmount=${state.orderTotal}` +
        `&ordName=${formData.ordName}` +
        `&ordEmailAddress=${formData.ordEmailAddress}` +
        `&ordPhoneNumber=${formData.ordPhoneNumber}` +
        `&ordAddress1=${formData.ordAddress1}` +
        `&ordCity=${formData.ordCity}` +
        `&ordPostalCode=${formData.ordPostalCode}` +
        `&approvedPage=${formData.approvedPage}`,
      {
        method: 'GET',
      },
    );

    if (response) {
      window.location.href = response.url;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" gutterBottom color="primary.main">
        Confirm & Pay
      </Typography>
      <Typography sx={{ mb: 3 }} color="secondary.main">
        Please review your registration prior to proceeding. You will receive an
        email confirmation with these details shortly.
      </Typography>

      <Card sx={{ p: 1, mb: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography
                color="text.secondary"
                gutterBottom
                variant="h6"
                component="div"
              >
                Selected Date and Time:
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  const data = { ...state, activeStep: 0 };
                  actions.updateAction(data);
                }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {selectedEventDetails.start &&
                  format(
                    parseISO(selectedEventDetails.start),
                    'EEEE, MMMM do, yyyy - h:mm a',
                  )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ p: 1, mb: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography
                color="text.secondary"
                gutterBottom
                variant="h6"
                component="div"
              >
                Selected Testing Components:
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  const data = { ...state, activeStep: 1 };
                  actions.updateAction(data);
                }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {state.cartItems.map((option) => (
                <Chip label={option} />
              ))}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              textAlign="right"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                color="text.secondary"
                sx={{ fontStyle: 'italic', pb: 0.3, pr: 1 }}
              >
                Total:
              </Typography>
              <Typography variant="h5" color="primary.main">
                ${state.orderTotal}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ p: 1 }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography
                color="text.secondary"
                gutterBottom
                variant="h6"
                component="div"
              >
                Your Information
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  const data = { ...state, activeStep: 2 };
                  actions.updateAction(data);
                }}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">Name:</Typography>
              <Typography>
                {state.firstName} {state.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">Date of Birth:</Typography>
              <Typography>
                {state.consolidatedDob &&
                  format(parseISO(state.consolidatedDob), 'yyyy-M-d')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">Email:</Typography>
              <Typography>{state.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">Phone:</Typography>
              <Typography>{state.phone}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">Address:</Typography>
              <Typography>{state.address}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">City:</Typography>
              <Typography>{state.city}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">Province/State:</Typography>
              <Typography>{state.region}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">Country:</Typography>
              <Typography>{state.country}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="text.secondary">Postal/ZIP Code:</Typography>
              <Typography>{state.postalCode}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid item xs={12}>
        <StepsNav steps={props.steps} />
      </Grid>
    </form>
  );
};

export default ConfirmStep;
