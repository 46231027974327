import { useForm, Controller } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MenuItem from '@mui/material/MenuItem';
import months from '../../common/months';
import countries from '../../common/countries';
import states from '../../common/states';

import StepsNav from './StepsNav';

const CandidateInfoStep = (props) => {
  const { handleSubmit, control } = useForm();
  const { actions, state } = useStateMachine({ updateAction });
  let activeStep = state.activeStep;

  const handleDobMonthChange = (event) => {
    const data = { ...state, dobMonth: event.target.value };
    actions.updateAction(data);
  };

  const handleDobDayChange = (event) => {
    const data = { ...state, dobDay: event.target.value };
    actions.updateAction(data);
  };

  const handleDobYearChange = (event) => {
    const data = { ...state, dobYear: event.target.value };
    actions.updateAction(data);
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    const setDob = () => {
      const dob = new Date();
      dob.setMonth(state.dobMonth);
      dob.setDate(parseInt(state.dobDay));
      dob.setYear(parseInt(state.dobYear));
      dob.setHours(0, 0, 0, 0);

      return dob;
    };
    const dob = setDob();

    const update = {
      ...state,
      activeStep: activeStep + 1,
      consolidatedDob: dob.toISOString(),
      ...data,
    };
    actions.updateAction(update);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" gutterBottom color="primary.main">
        Your Information
      </Typography>
      <Alert severity="info" sx={{ mb: 3 }}>
        A valid email is required to confirm registration
      </Alert>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            defaultValue={state.email}
            rules={{ required: 'Enter your email address' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email"
                id="email"
                type="email"
                placeholder="example@domain.com"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="firstName"
            control={control}
            defaultValue={state.firstName}
            rules={{ required: 'Enter your first name' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="First Name"
                id="firstName"
                placeholder="First Name"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="lastName"
            control={control}
            defaultValue={state.lastName}
            rules={{ required: 'Enter your last name' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Last Name"
                id="lastName"
                placeholder="Last Name"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="phone"
            control={control}
            defaultValue={state.phone}
            rules={{ required: 'Enter your phone number' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Phone"
                id="phone"
                placeholder="905-555-5555"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="dobMonth"
            control={control}
            defaultValue={state.dobMonth}
            rules={{
              required: 'Enter your birth month',
              max: { value: 12, message: 'Invalid month' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="dobMonth"
                label="Birth Month"
                value={value}
                onChange={(event) => {
                  onChange(event);
                  handleDobMonthChange(event);
                }}
                error={!!error}
                helperText={error ? error.message : null}
                select
                sx={{ width: '34%' }}
              >
                {months.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            name="dobDay"
            control={control}
            defaultValue={state.dobDay}
            rules={{
              required: 'Enter your birth day',
              min: { value: 1, message: 'Invalid day' },
              max: { value: 31, message: 'Invalid day' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="dobDay"
                label="Birth Day"
                placeholder="Day"
                type="number"
                value={value}
                onChange={(event) => {
                  onChange(event);
                  handleDobDayChange(event);
                }}
                error={!!error}
                helperText={error ? error.message : null}
                sx={{ width: '33%' }}
              />
            )}
          />
          <Controller
            name="dobYear"
            control={control}
            defaultValue={state.dobYear}
            rules={{
              required: 'Enter your birth year',
              min: { value: 1950, message: 'Out of range' },
              max: { value: 2020, message: 'Invalid year' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="dobYear"
                label="Birth Year"
                placeholder="Year"
                type="number"
                value={value}
                onChange={(event) => {
                  onChange(event);
                  handleDobYearChange(event);
                }}
                error={!!error}
                helperText={error ? error.message : null}
                sx={{ width: '33%' }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="address"
            control={control}
            defaultValue={state.address}
            rules={{ required: 'Enter your address' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="address"
                label="Address"
                placeholder="123 Main Street"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="city"
            control={control}
            defaultValue={state.city}
            rules={{ required: 'Enter your city' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="city"
                label="City"
                placeholder="St. Catharines"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="country"
            control={control}
            defaultValue={state.country}
            rules={{ required: 'Select your country' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="country"
                label="Country"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                select
                fullWidth
              >
                {countries.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="region"
            control={control}
            defaultValue={state.region}
            rules={{ required: 'Select your province/state' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="region"
                label="Province/State"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                select
                fullWidth
              >
                {states.map((option) => (
                  <MenuItem key={option.key} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="postalCode"
            control={control}
            defaultValue={state.postalCode}
            rules={{ required: 'Enter your postal/ZIP code' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id="postalCode"
                label="Postal/ZIP Code"
                placeholder="Postal/ZIP Code"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <StepsNav steps={props.steps} />
        </Grid>
      </Grid>
    </form>
  );
};

export default CandidateInfoStep;
