import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';

import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import StepsNav from './StepsNav';

const AddTestStep = (props) => {
  const { handleSubmit } = useForm();
  const { actions, state } = useStateMachine({ updateAction });
  const [testingComponents, setTestingComponents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();
  let activeStep = state.activeStep;

  useEffect(() => {
    const fetchTestingComponents = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/testingcomponents`,
      );

      if (!response.ok) {
        throw new Error('Could not get testing components!');
      }

      const responseData = await response.json();

      setTestingComponents(responseData);
      setIsLoading(false);
    };

    fetchTestingComponents().catch((error) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
  }, [actions, state.cartItems]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (httpError) {
    return (
      <Box>
        <Alert severity="error">{httpError}</Alert>
      </Box>
    );
  }

  const handleAddItemToCart = (event) => {
    const itemId = parseInt(event.target.name);
    const found = state.cartItems.includes(itemId);

    if (!found) {
      actions.updateAction({
        cartItems: [...state.cartItems, itemId],
      });

      const foundItem = testingComponents.find(({ id }) => id === itemId);
      actions.updateAction({
        orderTotal: state.orderTotal + parseInt(foundItem.price),
      });
    }
  };

  const handleRemoveItemFromCart = (event) => {
    const itemId = parseInt(event.target.name);
    const found = state.cartItems.includes(itemId);

    if (found) {
      const cartItems = state.cartItems.filter((item) => item !== itemId);
      actions.updateAction({
        cartItems: cartItems,
      });

      const foundItem = testingComponents.find(({ id }) => id === itemId);
      actions.updateAction({
        orderTotal: state.orderTotal - parseInt(foundItem.price),
      });
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();

    if (state.orderTotal === 0) {
      alert('Please add at least one testing component');
      return;
    }

    // @AB TODO: revalidate if event date still available

    const update = {
      ...state,
      activeStep: activeStep + 1,
      ...data,
    };
    actions.updateAction(update);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" gutterBottom color="primary.main">
        Add Tests
      </Typography>
      <Typography sx={{ mb: 3 }} color="secondary.main">
        Select testing components to add
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {testingComponents.map((option) => (
            <Card
              variant="outlined"
              key={option.id}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <CardContent>
                <Typography variant="h6">
                  {option.name}
                  <Link href={option.link} target="_blank">
                    <IconButton color="primary">
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Link>
                </Typography>
                <Typography color="secondary.main">
                  {option.description}
                </Typography>
              </CardContent>
              <CardContent>
                <Button
                  variant={
                    state.cartItems.includes(option.id)
                      ? 'outlined'
                      : 'contained'
                  }
                  name={option.id}
                  onClick={
                    state.cartItems.includes(option.id)
                      ? handleRemoveItemFromCart
                      : handleAddItemToCart
                  }
                  sx={{ width: '100px', height: '75px' }}
                >
                  {'$' + Math.round(option.price)}
                  <br />
                  {state.cartItems.includes(option.id) ? 'Remove' : 'Add'}
                </Button>
              </CardContent>
            </Card>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              textAlign="right"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                color="text.secondary"
                sx={{ fontStyle: 'italic', pb: 0.3, pr: 1 }}
              >
                Total:
              </Typography>
              <Typography variant="h5" color="primary.main">
                ${state.orderTotal}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StepsNav steps={props.steps} />
        </Grid>
      </Grid>
    </form>
  );
};

export default AddTestStep;
