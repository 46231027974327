import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';

import { format, parseISO } from 'date-fns';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Alert from '@mui/material/Alert';

import StepsNav from './StepsNav';

const DateTimeStep = (props) => {
  const { handleSubmit, control, setValue } = useForm();
  const { actions, state } = useStateMachine({ updateAction });
  const [eventDates, setEventDates] = useState([]);
  const [filteredEventDates, setfilteredEventDates] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState();
  let activeStep = state.activeStep;

  const fetchFilteredEvents = async (date) => {
    date = parseISO(date);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/events/open?start=${date}`,
    );

    if (!response.ok) {
      throw new Error('Could not get filtered events!');
    }

    const responseData = await response.json();

    setfilteredEventDates(responseData);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/events/open?uniqueDatesOnly=true`,
      );

      if (!response.ok) {
        throw new Error('Could not get events!');
      }

      const responseData = await response.json();

      setEventDates(responseData);
      setIsLoading(false);
    };

    fetchEvents().catch((error) => {
      setIsLoading(false);
      setHttpError(error.message);
    });

    if (state.eventDate) {
      fetchFilteredEvents(state.eventDate).catch((error) => {
        setHttpError(error.message);
        setIsLoading(false);
      });
    }
  }, [actions, state.eventDate, state.selectedEvent]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (httpError) {
    return (
      <Box>
        <Alert severity="error">{httpError}</Alert>
      </Box>
    );
  }

  const handleEventDateChange = (event) => {
    actions.updateAction({
      selectedEvent: '',
    });
    setValue('selectedEvent', '');

    fetchFilteredEvents(event.target.value).catch((error) => {
      setHttpError(error.message);
    });

    actions.updateAction({
      eventDate: event.target.value,
    });
  };

  const handleSelectedEventChange = (event) => {
    actions.updateAction({
      selectedEvent: event.target.value,
    });
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    const confirm = window.confirm(
      `IMPORTANT: Your selected time will be reserved for ${process.env.REACT_APP_EVENT_TOKEN_EXPIRY}.\nYou must complete checkout before then to avoid losing your time slot.`,
    );

    if (!confirm) {
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/eventlock`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          event: { id: data.selectedEvent },
          token: state.token,
        }),
      },
    );
    const result = await response.json();

    if (!result) {
      alert(
        'Sorry, that time is unavailable. Please choose a different time slot.',
      );
      return;
    }

    const update = {
      ...state,
      activeStep: activeStep + 1,
      token: result.token,
      ...data,
    };
    actions.updateAction(update);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" gutterBottom color="primary.main">
        Date & Time
      </Typography>
      <Typography sx={{ mb: 3 }} color="secondary.main">
        Choose an available test date and time.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="eventDate"
            control={control}
            defaultValue={state.eventDate}
            rules={{ required: 'Select an available date' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                name="eventDate"
                label="Available Dates"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  handleEventDateChange(e);
                }}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                }}
                select
                fullWidth
              >
                {eventDates.map((option) => (
                  <MenuItem key={option} value={option}>
                    {format(parseISO(option), 'EEEE, MMMM do, yyyy')}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '300px' }}
        >
          <Controller
            name="selectedEvent"
            control={control}
            defaultValue={state.selectedEvent}
            rules={{ required: 'Select an available time' }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                name="selectedEvent"
                label="Available Times"
                value={value}
                onChange={(e) => {
                  onChange(e);
                  handleSelectedEventChange(e);
                }}
                disabled={!filteredEventDates.length}
                error={!!error}
                helperText={error ? error.message : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
                select
                fullWidth
              >
                {filteredEventDates.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {format(parseISO(option.start), 'hh:mm a')}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <StepsNav steps={props.steps} />
        </Grid>
      </Grid>
    </form>
  );
};

export default DateTimeStep;
