import { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { useSearchParams } from 'react-router-dom';
import clearAction from './clearAction';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

export default function PaymentSuccess() {
  const [searchParams] = useSearchParams();
  const { actions, state } = useStateMachine({ clearAction });

  useEffect(() => {
    const saveBooking = async () => {
      const booking = {
        email: state.email,
        phone: state.phone,
        firstName: state.firstName,
        lastName: state.lastName,
        address: state.address,
        city: state.city,
        region: state.region,
        country: state.country,
        postalCode: state.postalCode,
        dob: state.consolidatedDob,
        event: state.selectedEvent,
        testingComponents: state.cartItems,
        token: state.token,
        paymentId: searchParams.get('trnId'),
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/bookings`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(booking),
        },
      );

      const result = await response.json();
      if (result) {
        actions.clearAction();
      }
      return result;
    };

    if (searchParams.get('trnApproved') && state.activeStep !== 0) {
      saveBooking().catch((error) => {
        return error;
      });
    }
  }, [
    actions,
    searchParams,
    state.activeStep,
    state.address,
    state.cartItems,
    state.city,
    state.consolidatedDob,
    state.country,
    state.dobDay,
    state.dobMonth,
    state.dobYear,
    state.email,
    state.firstName,
    state.lastName,
    state.phone,
    state.postalCode,
    state.region,
    state.selectedEvent,
    state.token,
  ]);

  const handleBookAgainClick = () => {
    actions.clearAction();
    window.location.href = '/';
  };

  return (
    <Paper variant="outlined" sx={{ p: 3, my: 3 }}>
      <Typography variant="h5" gutterBottom color="primary.main">
        Registration Complete
      </Typography>
      <Typography sx={{ mb: 3 }} color="secondary.main">
        Thanks for your registration! You should be receiving a confirmation
        email shortly. If you have not received your confirmation after 24
        hours, please{' '}
        <Link href="https://fireontario.com/contact-us#">contact us.</Link>
      </Typography>
      <Box textAlign="center">
        <ButtonGroup variant="outlined">
          <Button onClick={handleBookAgainClick}>
            Book another appointment
          </Button>
          <Button
            href="https://www.fireontario.com/"
            variant="contained"
            endIcon={<ExitToAppIcon />}
          >
            Return to the main site
          </Button>
        </ButtonGroup>
      </Box>
    </Paper>
  );
}
