import { Fragment } from 'react';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';

import DateTimeStep from './DateTimeStep';
import AddTestStep from './AddTestStep';
import CandidateInfoStep from './CandidateInfoStep';
import ConfirmStep from './ConfirmStep';
import PaymentSuccess from './PaymentSuccess';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ['Date & Time', 'Add Tests', 'Your Info', 'Confirm & Pay'];

const getStepContent = (step) => {
  switch (step) {
    case 0:
      return <DateTimeStep steps={steps} />;
    case 1:
      return <AddTestStep steps={steps} />;
    case 2:
      return <CandidateInfoStep steps={steps} />;
    case 3:
      return <ConfirmStep steps={steps} />;
    default:
      throw new Error('Unknown step');
  }
};

export default function RegistrationSteps() {
  const { state } = useStateMachine({ updateAction });
  let activeStep = state.activeStep;

  return (
    <Fragment>
      <Stepper activeStep={activeStep} sx={{ my: 3 }} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Paper variant="outlined" sx={{ p: 3 }}>
        <Fragment>
          {activeStep === steps.length ? (
            <PaymentSuccess />
          ) : (
            <Fragment>{getStepContent(activeStep)}</Fragment>
          )}
        </Fragment>
      </Paper>
    </Fragment>
  );
}
