const initialStoreState = {
  activeStep: 0,
  orderTotal: 0,
  eventDate: '',
  consolidatedDob: '',
  selectedEvent: '',
  cartItems: [],
  token: '',
};
export default initialStoreState;
