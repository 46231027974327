import { StateMachineProvider, createStore } from 'little-state-machine';
import Container from '@mui/material/Container';
import '@fontsource/roboto/500.css';
import RegistrationForm from './components/RegistrationSteps/RegistrationForm';
import initialStoreState from './common/initialStoreStore';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import logo from './assets/onfire-dark.svg';
import Box from '@mui/material/Box';
import PaymentSuccess from './components/RegistrationSteps/PaymentSuccess';

createStore(initialStoreState);

function App() {
  return (
    <StateMachineProvider>
      <Container maxWidth="md" sx={{ my: 3 }}>
        <Box sx={{ maxWidth: 500 }}>
          <img src={logo} alt="FireFighter Services Of Ontario logo" />
        </Box>
        <Router>
          <Routes>
            <Route path="/complete" element={<PaymentSuccess />} />
            <Route path="/" element={<RegistrationForm />} />
          </Routes>
        </Router>
      </Container>
    </StateMachineProvider>
  );
}

export default App;
