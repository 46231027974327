import { Fragment } from 'react';
import { useStateMachine } from 'little-state-machine';
import updateAction from './updateAction';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CheckIcon from '@mui/icons-material/Check';

const StepsNav = (props) => {
  const { actions, state } = useStateMachine({ updateAction });
  let activeStep = state.activeStep;

  const isLastStep = activeStep === props.steps.length - 1;

  const handleBack = () => {
    const data = { ...state, activeStep: activeStep - 1 };
    actions.updateAction(data);
  };

  return (
    <Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {activeStep !== 0 && (
          <Button
            onClick={handleBack}
            sx={{ mt: 3, ml: 1 }}
            startIcon={<ArrowLeftIcon />}
          >
            Back
          </Button>
        )}
        <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          endIcon={isLastStep ? <CheckIcon /> : <ArrowRightIcon />}
          type="submit"
        >
          {isLastStep ? 'Confirm & Pay ' : 'Next'}
        </Button>
      </Box>
    </Fragment>
  );
};

export default StepsNav;
